window.addEventListener('scroll', () => {

    let elem = document.documentElement;

    let scrollHeight = Math.max(
        document.body.scrollHeight, elem.scrollHeight,
        document.body.offsetHeight, elem.offsetHeight,
        document.body.clientHeight, elem.clientHeight
    );


    let scrollContent = (window.scrollY/(scrollHeight-window.innerHeight)).toFixed(3);
    let scrollHeader = Math.min(1,elem.scrollTop/elem.clientHeight).toFixed(3);

    //console.log(`${document.documentElement.scrollTop} - ${document.documentElement.clientHeight}`);

    document.body.style.setProperty('--sc', scrollContent);
    document.body.style.setProperty('--sh', scrollHeader);
}, false);

console.log('moin h0lgrr');
